(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var _util = require("./_util.js");

jQuery(document).ready(function ($) {
  if ($('#banner').length) {
    var setBannerCircle = function setBannerCircle(total) {
      setTimeout(function () {
        $('.swiper-pagination-round .swiper-pagination-bullet').each(function (i) {
          if (total < 3) {
            i++;
            var start = 180;
            var end = 270;
            var deg = (end - start) / (total + 1) * i + start;
            console.log(deg);
          } else {
            var start = 200;
            var end = 270;

            if (i == 0) {
              var deg = start;
            } else {
              var deg = (end - start) / total * i + start;
            }
          }

          $(this).css('transform', "rotate(" + deg + "deg) translate(2.75rem)");
        });
      }, 0);
    };

    $('#fullpage-home').fullpage({
      responsiveWidth: 760,
      anchors: ['slider', 'product', 'solution', 'about', 'news', 'footer'],
      onLeave: function onLeave(index, destination, direction) {
        var nextIndex = destination.index;

        if (nextIndex == 1 || nextIndex == 3 || nextIndex == 4 || nextIndex == 5) {
          $("#hd").addClass('white-hd');
        } else {
          $("#hd").removeClass('white-hd');
        } // console.log(nextIndex);


        if (nextIndex != 3) {
          dp.pause();
        }
        /*if(nextIndex == 3){
        	$('.count-to').countTo();
        }*/

      }
    });
    $('.count-to').one('inview', function (event, isInView) {
      if (isInView) {
        $('.count-to').countTo();
      } else {}
    });
    var swiperBanner = new Swiper('#banner', {
      loop: true,
      speed: 500,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      on: {
        init: function init() {
          var total = this.slides.length - 2;
          setBannerCircle(total);
        },
        slideChangeTransitionStart: function slideChangeTransitionStart() {},
        transitionEnd: function transitionEnd() {
          $('#banner .swiper-slide-active').removeClass('move-out').addClass('move-in').siblings().removeClass('move-in');
          var total = this.slides.length - 2;
          $('#banner-count em').html((0, _util.pad)(this.realIndex + 1));
          $('#banner-count s').html((0, _util.pad)(total));
        }
      },
      autoplay: {
        delay: 5000
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    });
    var swiperPro = new Swiper('#swiper-pro', {
      speed: 500,
      autoplay: {
        delay: 5000
      },
      loop: false,
      on: {
        beforeInit: function beforeInit() {
          $('.i-pro-hd .item:first').addClass('on');
        },
        init: function init() {},
        transitionStart: function transitionStart() {
          $('.i-pro-hd .item').eq(this.realIndex).addClass('on').siblings('.item').removeClass('on');
          $('.i-pro-hd').scrollCenter('.on');
        }
      }
    });
    $('.i-pro-hd .item').click(function (e) {
      var i = $(this).index();
      swiperPro.slideTo(i);
      return false;
    });
    var swiperBg = new Swiper('#swiper-bg', {
      effect: 'fade',
      speed: 500,
      loop: false,
      on: {
        beforeInit: function beforeInit() {},
        init: function init() {},
        transitionStart: function transitionStart() {}
      }
    });
    var swiperSolution = new Swiper('#swiper-solution', {
      // autoHeight:true,
      // effect: 'fade',
      speed: 500,
      scrollbar: {
        el: '.swiper-scrollbar-outer',
        hide: false
      },

      /*autoplay: {
         delay: 5000,
      },*/
      loop: false,
      on: {
        beforeInit: function beforeInit() {
          $('.solution-nav .item:first').addClass('on');
        },
        init: function init() {},
        transitionStart: function transitionStart() {
          console.log(this.realIndex);
          $('.solution-nav .item').eq(this.realIndex).addClass('on').siblings('.item').removeClass('on');
        }
      }
    });
    swiperBg.controller.control = swiperSolution;
    swiperSolution.controller.control = swiperBg;
    $('.solution-nav .item').click(function (e) {
      var i = $(this).index();
      swiperSolution.slideTo(i);
      return false;
    });
    var swiperNews = new Swiper('#swiper-news', {
      speed: 400,
      loop: false,
      on: {
        beforeInit: function beforeInit() {
          $('.news-tab a:first').addClass('on');
        },
        init: function init() {},
        transitionStart: function transitionStart() {
          $('.news-tab a').eq(this.realIndex).addClass('on').siblings('a').removeClass('on');
        }
      }
    });
    $('.news-tab a').click(function (e) {
      var i = $(this).index();
      swiperNews.slideTo(i);
      return false;
    });
  }
});

},{"./_util.js":7}],2:[function(require,module,exports){
"use strict";

jQuery(document).ready(function ($) {
  function setMobileNav() {
    if (!$('.inner-bar').length > 0) return;
    if ($('.inner-bar').hasClass('product-bar')) return;
    var cur = $('.nav-inner .on').html();
    var all = $('.nav-inner').wrap('<div/>').parent().html();
    $('.nav-inner').unwrap();
    var str = '<div class="cat-nav">' + '<h4><span>' + cur + '</span><i class="iconfont icon-down"></i></h4>' + '<div class="cat-sub">' + all + '</div>';
    '</div>';
    $(str).insertAfter('.inner-bar');
    $('.cat-sub ul').removeClass('nav-inner');
    $('body').on('click', '.cat-nav h4', function () {
      if ($(this).parent().hasClass('active')) {
        $(".cat-nav .cat-sub").slideUp(100);
        $(this).parent().removeClass('active');
      } else {
        $(this).parent().addClass('active');
        $(".cat-nav .cat-sub").slideDown(300);
      }

      return false;
    });
    $(document).click(function (e) {
      var container = $(".cat-nav");

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.removeClass('active').find(".cat-sub").slideUp(100);
      }
    });
  }

  setMobileNav();
});

},{}],3:[function(require,module,exports){
"use strict";

jQuery(document).ready(function ($) {
  $(".m_nav").pushmenu({
    button: "#nav_toggle"
  });
  $("#m-nav li").each(function () {
    var sub = $(this).find(".sub-menu");
    var link = sub.find("a").length > 0;

    if (sub && link) {
      $(this).addClass("has-sub");
      $(this).children("a").append('<div class="iconfont icon-arrow-right"></div>');
    }
  });
  $("#m-nav > li > a").click(function () {
    var sub = $(this).next(".sub-menu");

    if (!$(this).parents("li").hasClass("has-sub")) {
      return true;
    }

    if (sub.is(":visible")) {
      sub.slideUp();
      $(this).removeClass("on");
    } else {
      $("#m-nav .sub-menu").slideUp();
      $("#m-nav li .on").removeClass("on");
      $(this).addClass("on");
      sub.slideDown();
    }

    return false;
  });
});

},{}],4:[function(require,module,exports){
"use strict";

jQuery(document).ready(function ($) {
  // Code that uses jQuery's $ can follow here.
  var $body = $('body');
  var $hd = $('#hd');

  if ($('body').hasClass('home')) {
    $(window).scroll(function () {
      if ($(window).scrollTop() > 10) {
        $hd.addClass('white-hd');
      } else {
        $hd.removeClass('white-hd');
      }
    }).scroll();
  }

  $('#nav li').mouseenter(function () {
    var sub = $(this).find('.sub-menu');

    if (sub.length && sub.find('a').length) {
      var autoHeight = sub.get(0).scrollHeight;
      sub.css({
        "height": autoHeight
      });
    }
  }).mouseleave(function () {
    var sub = $(this).find('.sub-menu');

    if (sub.length) {
      sub.css({
        "height": 0
      });
    }
  });
});

},{}],5:[function(require,module,exports){
"use strict";

jQuery(document).ready(function ($) {
  var $hd = $('#hd');
  var $body = $('body');
  var $input = $(".search-box .form-ele");
  $('.search-ico').click(function (e) {
    $body.removeClass('show-m-search').toggleClass('show-pc-search');

    if ($body.hasClass('white-hd')) {
      $body.addClass('black-hd-bg');
    } else if ($body.hasClass('transparent-hd')) {
      $body.addClass('white-hd-bg');
    }

    setTimeout(function () {
      $input.focus();
    }, 300);
    return false;
  });
  $('body').click(function (e) {
    var container = $(".search-box");

    if (!container.is(e.target) && container.has(e.target).length === 0) {
      closeSearch();
    }
  });
  $('.search-ico .icon-x').click(function (e) {
    closeSearch();
    return false;
  });

  function closeSearch() {
    $input.val('');
    $body.removeClass('show-pc-search');
  }
});
jQuery(document).ready(function ($) {
  var $trigger = $('.m-search-ico');
  var $search = $('.search-m');
  var $input = $(".search-m .form-ele");
  var $close = $search.find('.icon-x');
  var $body = $('body');
  var $nav_toggle = $('#nav_toggle');
  $trigger.click(function (e) {
    if ($nav_toggle.hasClass('active')) {
      $nav_toggle.click();
    }

    $body.removeClass('show-pc-search').toggleClass('show-m-search');
    setTimeout(function () {
      $input.focus();
    }, 300);
    return false;
  });
  $close.click(function (e) {
    closeSearch();
  });

  function closeSearch() {
    $input.val('');
    $body.removeClass('show-m-search');
  }

  $('body').click(function (e) {
    var container = $(".search-m");

    if (!container.is(e.target) && container.has(e.target).length === 0) {
      closeSearch();
    }
  });
});

},{}],6:[function(require,module,exports){
"use strict";

jQuery(document).ready(function ($) {
  function stickNav() {
    var $nav = $('.product-bar');
    var isPc = $('#hd').is(':visible');
    if ($nav.length == 0) return;

    if ($(window).width() < 760) {
      $nav.unstick();
    } else {
      $nav.unstick();
      $nav.sticky({
        topSpacing: isPc ? $('#hd').height() : $('.m_header').height(),
        zIndex: 30
      });
      $nav.sticky('update');
    }
  }

  stickNav();
  var fixnavBar = debounce(function () {
    stickNav();
  }, 250, false);

  if ($('.product-bar').length) {
    $(window).on('resize', fixnavBar);
    var offset = $('#hd').height() + $('.product-bar').height();
    $('.product-bar .nav-inner li a').pageNav({
      scroll_shift: offset,
      active_shift: offset
    });
  }
});

},{}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pad = exports.judgeClient = void 0;

var judgeClient = function judgeClient() {
  var client = '';

  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    client = 'iOS';
  } else if (/(Android)/i.test(navigator.userAgent)) {
    client = 'Android';
  } else {
    client = 'PC';
  }

  return client;
};

exports.judgeClient = judgeClient;

var pad = function pad(n) {
  return n < 10 ? "0" + n : n;
};

exports.pad = pad;

},{}],8:[function(require,module,exports){
"use strict";

require("./_nav.js");

require("./_search.js");

require("./_m-inner-nav.js");

require("./_m-nav.js");

require("./_stick_inner_bar.js");

require("./_index.js");

var _Swiper;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var $ = jQuery.noConflict();
var swiperAchieve = new Swiper('#swiper-achieve', {
  speed: 400,
  loop: true,
  slidesPerView: 3,
  slidesPerGroup: 3,
  autoplay: {
    delay: 5000
  },
  pagination: {
    el: '.swiper-achieve .swiper-pagination',
    clickable: true
  },
  on: {
    beforeInit: function beforeInit() {},
    init: function init() {},
    transitionStart: function transitionStart() {}
  },
  breakpoints: {
    1100: {
      slidesPerView: 2,
      slidesPerGroup: 1
    },
    760: {
      slidesPerGroup: 1,
      slidesPerView: 1
    }
  }
});
/*  var mySwiper = new Swiper("#swiper-certi", {
    spaceBetween: 38,
    // slidesPerView: 6.6,
    slidesPerView: 'auto',
    centeredSlides: true,
    roundLengths: true,
    loop: false,
    loopAdditionalSlides: 30,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
      preventClicks:true,
      on: {
        init: function () {
        },
        slideChangeTransitionStart : function() {
        },
        slideChangeTransitionEnd : function() {
        }
      },
  });
  mySwiper.zoom.in();*/

/*var swiper = new Swiper("#swiper-certi", {
    slidesPerView: "auto", 
    // slidesPerView: 6,
    // loop: 3,
    centeredSlides: true, 
    spaceBetween: 38,
    initialSlide: 1,
  });

// swiper.zoom.in();
// swiper.on("slideChangeTransitionStart", function() { swiper.zoom.out() });
swiper.on("slideChangeTransitionEnd", function() { 
	    // swiper.update(true);
 });*/

var swiper = new Swiper("#swiper-certi", (_Swiper = {
  slidesPerView: 'auto',
  spaceBetween: 0,
  speed: 500,
  centeredSlides: true,
  preventClicks: true
}, _defineProperty(_Swiper, "spaceBetween", 38), _defineProperty(_Swiper, "loopAdditionalSlides", 3), _defineProperty(_Swiper, "loop", true), _defineProperty(_Swiper, "autoplay", {
  delay: 5000
}), _defineProperty(_Swiper, "navigation", {
  nextEl: '.cert-next',
  prevEl: '.cert-prev'
}), _defineProperty(_Swiper, "on", {
  init: function init() {
    $('.swiper-slide').addClass('changed');
  },
  slideChangeTransitionStart: function slideChangeTransitionStart() {
    $('.swiper-slide').addClass('changing');
    $('.swiper-slide').removeClass('changed');
  },
  slideChangeTransitionEnd: function slideChangeTransitionEnd() {
    $('.swiper-slide').removeClass('changing');
    $('.swiper-slide').addClass('changed');
  }
}), _Swiper));
var swiperIntro = new Swiper('#swiper-scenes', {
  loop: true,
  centeredSlides: true,
  centeredSlidesBounds: true,
  slidesPerView: 'auto',
  spaceBetween: 0,
  loopAdditionalSlides: 3,

  /*pagination: {
    el: '.swiper-pagination',
    clickable: true
  },*/
  navigation: {
    nextEl: '.next-intro',
    prevEl: '.prev-intro'
  },
  autoplay: true,
  breakpoints: {
    768: {
      spaceBetween: 0 // navigation: false

    }
  }
});
var $modalJob = $('#modal-job-detail');
var $modalPad = $('#modal-job-detail .pad');
var $job_loc = $('#job-loc');
var $job_number = $('#job-number');
var $job_title = $('#job-title');
var $job_content = $('#job-content');
$('.list-recruitment a').click(function (e) {
  var id = $(this).attr('data-post-id');
  $modalJob.addClass('loading');
  $modalJob.modal({
    fadeDuration: 300,
    showClose: false
  });
  $.ajax({
    url: '/wp-json/rayjoo/v1/job/' + id,
    type: "GET",
    dataType: 'json',
    success: function success(response) {
      // var res = JSON.parse(response.data);
      var title = response.data.title.rendered;
      var content = response.data.content.rendered;
      var job_loc = response.data.acf.job_loc;
      var job_number = response.data.acf.job_number;
      $job_loc.text(job_loc);
      $job_number.text(job_number);
      $job_title.text(title);
      $job_content.html(content); // console.log(res.title.rendered);
    },
    complete: function complete() {
      $modalJob.removeClass('loading');
    }
  });
  e.preventDefault();
});

function initModal() {
  $job_title.text('');
  $job_content.html('');
}

$modalJob.on($.modal.AFTER_CLOSE, function (event, modal) {
  initModal();
  initFile();
}); // 简历

$('#file').change(function (e) {
  if (e.target.files.length) {
    $('#file-name').text(e.target.files[0].name);
    var size = e.target.files[0].size;
    $modalJob.addClass('show-submit');
  } else {
    $modalJob.removeClass('show-submit');
  } // $modalJob

});

function initFile() {
  $('#file')[0].value = '';
  $('#file-name').text('');
  $('#upload-progress').text('');
  $('#file').change();
}

$('#delete').click(function () {
  initFile();
});
$('#btn-submit-resume').click(function () {
  var formData = new FormData();
  var file = $('#file')[0].files[0];
  var file_size = Math.round(file.size / 1024);

  if (file_size > 10240) {
    alert('文件过大，请选择小于10MB的文件');
    return;
  }

  formData.append("file", file);
  formData.append("job_name", $job_title.text());
  $.ajax({
    url: '/wp-json/rayjoo/v1/upload',
    data: formData,
    type: 'POST',
    processData: false,
    contentType: false,
    xhr: function xhr() {
      var xhr = $.ajaxSettings.xhr();

      if (xhr.upload) {
        xhr.upload.addEventListener('progress', function (e) {
          var percentage = parseInt(e.loaded / e.total * 100);
          $('#upload-progress').text(percentage + '%');
        }, false);
      }

      return xhr;
    },
    success: function success(res) {
      if (res.message == 'success') {
        alert('上传成功');
      }
    },
    error: function error(request, status, _error) {
      alert('上传失败');
    },
    complete: function complete() {
      initFile();
      setTimeout(function () {
        $.modal.close();
      }, 600);
    }
  });
});
$("#form-contact").validate({
  rules: {
    username: 'required',
    phone: {
      phoneCN: true,
      required: true
    },
    email: 'required',
    content: 'required'
  },
  messages: {
    username: '请输入姓名',
    phone: '请输入电话',
    email: '请输入正确的邮箱',
    content: '请输入留言内容'
  },
  submitHandler: function submitHandler(form) {
    contactSubmit();
  }
});

function contactSubmit() {
  var user_name = $('#user_name').val();
  var user_tel = $('#user_tel').val();
  var uesr_email = $('#uesr_email').val();
  var user_content = $('#user_content').val();
  var isModal = $("#form-contact").parents('.modal').length;
  jQuery.ajax({
    url: '/wp-json/rayjoo/v1/contact',
    type: "POST",
    dataType: 'json',
    data: {
      user_name: user_name,
      user_tel: user_tel,
      uesr_email: uesr_email,
      user_content: user_content
    },
    success: function success(response) {
      alert(response.message); // jQuery("#contact").modal("hide");

      if (isModal) {
        setTimeout(function () {
          jQuery('#form-contact')[0].reset();
          jQuery('#validation-messages-container').empty();
          $.modal.close();
        }, 600);
      } else {
        jQuery('#form-contact')[0].reset();
        jQuery('#validation-messages-container').empty();
      }
    },
    error: function error(_error2) {
      alert('错误');
    },
    complete: function complete() {}
  });
} // 咨询


$('.btn-consult').click(function (e) {
  $("#modal-consult").modal({
    fadeDuration: 300,
    showClose: false
  });
  return false;
});

},{"./_index.js":1,"./_m-inner-nav.js":2,"./_m-nav.js":3,"./_nav.js":4,"./_search.js":5,"./_stick_inner_bar.js":6}]},{},[8]);
